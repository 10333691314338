import React, { useState } from 'react';

export const appContext = React.createContext();

const Provider = props => {
  const [longevity, setLongevity] = useState(null);
  const [growingEnv, setGrowingEnv] = useState(null);

  return (
    <appContext.Provider value={{
      longevity,
      updateLongevity: (value) => setLongevity(value),
      growingEnv,
      updateGrowingEnv: (value) => setGrowingEnv(value)
    }}>
      {props.children}
    </appContext.Provider>
  )
};

export default ({ element }) => (
    <Provider>
      {element}
    </Provider>
  );